import PlausibleProvider from "next-plausible";
import type { AppProps } from "next/app";
import dynamic from "next/dynamic";
import React, { useEffect } from "react";
import config from "../config/config";
import "../styles/main.scss";
import Head from "next/head";

require("intersection-observer");

function MyApp({ Component, pageProps }: AppProps) {
  useEffect(() => {
    (function (d, t) {
      var BASE_URL = "https://app.chatwoot.com";
      var g = d.createElement(t) as HTMLScriptElement,
        s = d.getElementsByTagName(t)[0];
      g.src = BASE_URL + "/packs/js/sdk.js";
      s.parentNode.insertBefore(g, s);
      g.onload = function () {
        (window as any).chatwootSDK.run({
          websiteToken: "eirx1TBSgsTFxzYLY9edSgEj",
          baseUrl: BASE_URL,
        });
      };
    })(document, "script");
  }, []);
  return (
    <PlausibleProvider domain={config.WEBSITE_DOMAIN}>
      <Head>
        <link rel="icon" href="/icon.svg" />
      </Head>
      <Component {...pageProps} />
    </PlausibleProvider>
  );
}

export default MyApp;
